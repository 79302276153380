<template>
  <b-card>
    <form-component :about-us-review="aboutUsReview" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/about-us-reviews/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/about-us-reviews';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      aboutUsReview: {
        name: '',
        position: '',
        review: '',
        lang: '',
        image: null,
      },
    };
  },

  methods: {
    async onSubmit(form) {
      const formData = new FormData();

      formData.append('name', form.name);
      formData.append('position', form.position);
      formData.append('review', form.review);
      formData.append('lang', form.lang);
      formData.append('image', form.image.file, form.image.file.fileName);

      try {
        await create(formData);

        this.showSuccessNotification('Dane zapisane', 'Opinia została pomyślnie dodana.');
        this.$router.push({ name: 'about-us-reviews-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił błąd podczas dodawania opinii. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
