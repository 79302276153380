<template>
  <validation-observer ref="aboutUsReviewObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>

        <b-form-group label="Język">
          <validation-provider #default="{ errors }" name="Język" rules="required">
            <v-select
              id="v-language-select"
              label="value"
              placeholder="Wybierz język"
              v-model="lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="languages" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Opinia</h4>
        <b-form-group label="Imię i nazwisko">
          <validation-provider #default="{ errors }" name="Imię i nazwisko" rules="required|min:2|max:56">
            <b-form-input v-model="form.name" placeholder="Imię i nazwisko" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Stanowisko">
          <validation-provider #default="{ errors }" name="Stanowisko" rules="required|min:2|max:56">
            <b-form-input v-model="form.position" placeholder="Stanowisko" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Opinia">
          <validation-provider #default="{ errors }" name="Opinia" rules="required|min:10|max:600">
            <b-form-textarea v-model="form.review" placeholder="Opinia" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group for="v-image" label="Zdjęcie">
          <b-button @click="toggleShowModal('image')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.image" type="hidden" name="Zdjęcie" v-model="files.image.file" />
            <input v-else type="hidden" name="Zdjęcie" v-model="files.image" />

            <ImageSelector
              v-if="showImagesModal.image"
              :files="filesArray"
              :show-modal="showImagesModal.image"
              @closeModal="toggleShowModal('image')"
              @submit="(fileList) => handleFile(fileList[0], 'image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview v-if="files.image" :image="files.image" @deleteImage="() => handleDelete('image')" />
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin.vue';
import { getLanguages, getLanguageByLang } from '@/constants/languages';

import ImageSelector from '@/components/shared/ImageSelector.vue';
import ImagePreview from '@/components/shared/ImagePreview.vue';

export default {
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    aboutUsReview: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImageSelector,
    ImagePreview,
  },

  data() {
    return {
      lang: {
        lang: 'pl',
        value: 'Polski',
      },
      form: this.aboutUsReview,
      languages: getLanguages(),
    };
  },

  created() {
    this.setFileUploadData(['image'], ['image']);
  },

  watch: {
    async aboutUsReview() {
      this.lang = getLanguageByLang(this.aboutUsReview.lang);
      await this.setImage(this.aboutUsReview.image, 'image');

      this.form = this.aboutUsReview;
    },
  },

  methods: {
    onSubmit() {
      const data = {
        ...this.form,
        image: this.files.image,
        lang: this.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.aboutUsReviewObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
