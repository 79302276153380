import axios from '../axios';

export const all = () => {
  return axios.get('/about-us-reviews/all');
};

export const show = (id) => {
  return axios.get(`/about-us-reviews/${id}`);
};

export const create = (payload) => {
  return axios.post('/about-us-reviews', payload);
};

export const update = (id, payload) => {
  return axios.post(`/about-us-reviews/${id}`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/about-us-reviews/${id}`);
};
