var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"aboutUsReviewObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Język"}},[_c('validation-provider',{attrs:{"name":"Język","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Opinia")]),_c('b-form-group',{attrs:{"label":"Imię i nazwisko"}},[_c('validation-provider',{attrs:{"name":"Imię i nazwisko","rules":"required|min:2|max:56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Imię i nazwisko","disabled":!_vm.lang},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Stanowisko"}},[_c('validation-provider',{attrs:{"name":"Stanowisko","rules":"required|min:2|max:56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Stanowisko","disabled":!_vm.lang},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Opinia"}},[_c('validation-provider',{attrs:{"name":"Opinia","rules":"required|min:10|max:600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Opinia","disabled":!_vm.lang},model:{value:(_vm.form.review),callback:function ($$v) {_vm.$set(_vm.form, "review", $$v)},expression:"form.review"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"for":"v-image","label":"Zdjęcie"}},[_c('b-button',{attrs:{"disabled":!_vm.lang},on:{"click":function($event){return _vm.toggleShowModal('image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.image.file),expression:"files.image.file"}],attrs:{"type":"hidden","name":"Zdjęcie"},domProps:{"value":(_vm.files.image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.image),expression:"files.image"}],attrs:{"type":"hidden","name":"Zdjęcie"},domProps:{"value":(_vm.files.image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "image", $event.target.value)}}}),(_vm.showImagesModal.image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.image},on:{"closeModal":function($event){return _vm.toggleShowModal('image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.image)?_c('ImagePreview',{attrs:{"image":_vm.files.image},on:{"deleteImage":function () { return _vm.handleDelete('image'); }}}):_vm._e()],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }